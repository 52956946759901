import React, { useContext, useState, useRef, useEffect } from "react";
import { Button, Typography, Box, TextField, IconButton } from "@mui/material";
import CustomAppBar from "../../components/CustomAppBar/CustomAppBar";
import { useTranslation } from "react-i18next";
import DeliveryDropdownSelector from "../../components/DeliveryDropdownSelector/DeliveryDropdownSelector";
import { useLocation, useNavigate } from "react-router-dom";
import CheckoutContext from "../../store/checkout-context";
import AuthContext from "../../store/auth-context";
import { Edit, EditAttributesSharp } from "@mui/icons-material";


const CheckoutBillingAddress = () => {
    const checkoutCtx = useContext(CheckoutContext);
    const authCtx = useContext(AuthContext);
    const { t } = useTranslation();
    const { state } = useLocation();
    const navigate = useNavigate();
    const [address, setAddress] = useState();

    const addressChangeHandler = (e) => {
        console.log(e.target.value);
        setAddress(e.target.value);
    }

    const saveAddressHandler = () => {
        if(address && address.length >= 5){
            checkoutCtx.saveDeliveryAddress({ ...checkoutCtx.deliverAddress, address });
            navigate('/checkout-confirm');
        };
        
    }

    useEffect(() => {
        setAddress(checkoutCtx.deliverAddress.address);
    }, [checkoutCtx.deliverAddress.address]);


    return (
        <Box id="CheckoutRegisterPage">
            <CustomAppBar hasBack backRoute={state?.from} backDark notif title={t("Checkout")} />
            <Box sx={{ p: '16px' }}>
                <Typography variant="h6">{t('Delivery Information')}</Typography>
                <Typography sx={{ mb: '20px' }}>{t('Please provide more details about your location')}</Typography>
                {/* <CheckoutStepper step={1} /> */}

                <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', mb: '20px', alignItems: 'center' }}>
                    <Box>
                        <Typography>
                            {t('Your Selected Location')}
                        </Typography>
                        <Typography fontSize={'14px'} fontWeight={"bold"}>
                            {checkoutCtx.deliverAddress?.cityName}, {authCtx.branch?.name}, {checkoutCtx.deliverAddress?.zName}
                        </Typography>
                    </Box>
                    <Box>
                        <Button sx={{ fontSize: '12px' }} onClick={() => navigate('/delivery')} variant="text" startIcon={<Edit />}>
                            {t('Change')}
                        </Button>
                    </Box>
                </Box>

                <Box sx={{ mb: 2 }}>
                    <TextField rows={4} multiline onChange={addressChangeHandler} value={address} defaultValue={checkoutCtx.deliverAddress.address} id="filled-basic" sx={{ width: '100%', mb: 1 }} label={t('Delivery address')} variant="filled" />
                    <Typography fontSize={12}>{t('Please provide us with your street name, building number, floor and any extra details for delivery')}</Typography>
                </Box>

                <Button disableElevation
                    disabled={address === null || address?.length < 5}
                    sx={{ width: '100%' }}
                    size="large"
                    variant="contained"
                    onClick={saveAddressHandler}>{t('Continue')}</Button>

                {/* <DeliveryDropdownSelector showAddress={true} /> */}
            </Box>
        </Box>
    )
};

export default CheckoutBillingAddress;

