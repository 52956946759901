import { Box, Divider, Grid, Typography } from '@mui/material';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

const OrderMeta = ({ order }) => {
    const { t, i18n } = useTranslation();
    return (
        <>
            <Box sx={{ mb: '16px' }}>
                <Box><Typography variant='h6' sx={{ marginBottom: '16px' }}>{t('Order Details')}</Typography></Box>
                <Box>
                <Grid container sx={{ mb: '16px' }} columnSpacing={1}>
                        <Grid item xs={6}>
                            <Typography fontWeight="bold">{t('Time and Date')}</Typography>
                            <Typography>{(moment(new Date(order?.created_at)).locale(i18n.resolvedLanguage).format('DD-MM-YYYY,  hh:mm A'))}</Typography>
                        </Grid>
                       
                    </Grid>
                    <Grid container sx={{ mb: '16px' }}  columnSpacing={1}>
                        <Grid item xs={6}>
                            <Typography fontWeight="bold">{t('Branch')}</Typography>
                            <Typography>{order?.branch_name}</Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography fontWeight="bold">{t('Type')}</Typography>
                            <Typography textTransform="capitalize">{t(order?.type)}</Typography>
                        </Grid>
                    </Grid>
                    <Grid container sx={{ mb: '16px' }} columnSpacing={1}>
                        <Grid item xs={6}>
                            <Typography fontWeight="bold">{t('Status')}</Typography>
                            <Typography textTransform="capitalize">{t(order?.status)}</Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography fontWeight="bold">{t('Payment')}</Typography>
                            <Typography textTransform="capitalize">{t(order?.payment)}</Typography>
                        </Grid>
                    </Grid>
                   { order?.note && <Grid container  columnSpacing={1}>
                        <Grid item xs={12}>
                            <Typography fontWeight="bold">{t('Notes')}</Typography>
                            <Typography textTransform="capitalize">{order?.note}</Typography>
                        </Grid>
                       
                    </Grid>}
                </Box>
            </Box>
            <Divider sx={{ marginBottom: '16px' }}/>
            <Box sx={{ mb: '16px' }}>
                <Box><Typography variant='h6' sx={{ marginBottom: '16px' }}>{t('Delivery Details')}</Typography></Box>
                <Box>
                    <Grid container sx={{ mb: '16px' }} columnSpacing={1}>
                   { order?.delivery_info.address && <Grid item xs={12} sx={{marginBottom:'10px'}}>
                            <Typography fontWeight="bold">{t('Address')}</Typography>
                            <Typography>{order?.delivery_info.address}</Typography>
                        </Grid>}
                      {/* { order?.delivery_info.build_num &&  <Grid item xs={6}  sx={{marginBottom:'10px'}}>
                            <Typography fontWeight="bold">{t('Build Number')}</Typography>
                            <Typography>{order?.delivery_info.build_num}</Typography>
                        </Grid>} */}
                      {/* { order?.delivery_info.flat_num &&  <Grid item xs={6}  sx={{marginBottom:'10px'}}>
                            <Typography fontWeight="bold">{t('Flat Number')}</Typography>
                            <Typography>{order?.delivery_info.flat_num}</Typography>
                        </Grid>} */}
                       { order?.phone && <Grid item xs={12}  sx={{marginBottom:'10px'}}>
                            <Typography fontWeight="bold">{t('Mobile')}</Typography>
                            <Typography>{order?.phone}</Typography>
                        </Grid>}
                    </Grid>
                 
                </Box>
            </Box>
        </>

    )
};

export default OrderMeta;