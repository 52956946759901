import React, { useEffect, useState } from 'react';
import axiosInstance from '../config/axios';

const AuthContext = React.createContext({
    token: null,
    branch: null,
    saveBranch: (barnch) => { },
    validate: (token) => { },
    isLoggedIn: false,
    login: (tkn, br) => { },
    logout: () => { },
    sendOtp: (phone) => { },
    clearOtp: () => { },
    otpError: undefined
});

export const AuthProvider = (props) => {

    const initialToken = localStorage.getItem('rdx_token');
    const [token, setToken] = useState(initialToken);
    const initialBranch = localStorage.getItem('rdx_branch') !== undefined ? JSON.parse(localStorage.getItem('rdx_branch')) : { 'branch': null, 'id': null };
    const [branch, setBranch] = useState(initialBranch);
    const [otpError, setOtpError] = useState();

    useEffect(() => {
        if (localStorage.getItem('rdx_branch')) {
            setBranch(JSON.parse(localStorage.getItem('rdx_branch')));
        };
        if (token) {
            axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        }
    }, [token]);


    let userIsLoggedIn = !!token;

    const authCtx = {
        token: token,
        branch: branch,
        isLoggedIn: userIsLoggedIn,
        sendOtp: async (phone) => {
            setOtpError(undefined);
            try {
                await axiosInstance.post(`/customer/send-otp-msg?phone=0${phone}`, {});
            } catch (error) {
                if (error.response.status === 400) {
                    setOtpError(true);
                };

            }
        },
        clearOtp: () => {
            setOtpError(undefined);
        },
        otpError,
        saveBranch: (br) => {
            setBranch(br);
            localStorage.setItem('rdx_branch', JSON.stringify(br));
        },
        login: (tkn) => {
            setToken(tkn);
            localStorage.setItem('rdx_token', tkn);
        },
        validate: async (token) => {
            try {
                const validRes = await axiosInstance.post('/customer-token/validation', { token: token });
                if (validRes.data.message !== 'Not valid') {
                    setToken(token);
                    userIsLoggedIn = true;
                    localStorage.setItem('rdx_token', token);
                };
            } catch (error) {

            }

        },
        logout: () => {
            setToken(undefined);
            setBranch(undefined);
            localStorage.removeItem('rdx_token');
            localStorage.removeItem('rdx_branch');
            localStorage.removeItem('rdx_delivery');
            localStorage.removeItem('rdx_delivery_address');
            localStorage.removeItem('rdx_cart');
        }
    };

    return <AuthContext.Provider value={authCtx}>{props.children}</AuthContext.Provider>
}


export default AuthContext;