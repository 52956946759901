import BottomTabs from "../../components/BottomTabs/BottomTabs.js";
import CustomAppBar from "../../components/CustomAppBar/CustomAppBar";
import { Box, Button, Paper, Grid, Alert } from "@mui/material";
import CartList from "./Components/CartList/CartList";
import CartEmpty from "./Components/CartEmpty/CartEmpty";
import CartTotals from './Components/CartTotals/CartTotals';
import { Link, useNavigate } from "react-router-dom";
import { useContext } from "react";
import CartContext from "../../store/cart-context.js";
import { useTranslation } from "react-i18next";
import AuthContext from "../../store/auth-context.js";
import CheckoutContext from "../../store/checkout-context.js";
import InfoContext from "../../store/info-context.js";
import CustomerContext from "../../store/customer-context.js";

const CartPage = () => {
    const navigate = useNavigate();
    const authCtx = useContext(AuthContext);
    const cartCtx = useContext(CartContext);
    const infoCtx = useContext(InfoContext);
    const checkoutCtx = useContext(CheckoutContext);
    const customerCtx = useContext(CustomerContext);

    const { t } = useTranslation();

    const navigateToCheckout = () => {
        if (authCtx.isLoggedIn) {
            if (checkoutCtx.deliveryType === 'pickup') {
                navigate('/checkout-confirm')
            };
            if (checkoutCtx.deliveryType === 'delivery') {
                navigate('/delivery-information')
            };
        } else {
            checkoutCtx.setPendingCheckout(true);
            navigate("/login", {
                state: {
                    to: checkoutCtx.deliveryType === 'delivery' ? '/delivery-information' : '/checkout-confirm'
                }
            });

        }
    }

    return <>

        <Box id="CartPage" className="h-100">
            <CustomAppBar title={t("Shopping Cart")} backRoute={'/products'} enableGps={true} hasBack={true} backDark />

            <Box sx={{ flex: 1, height: 'calc(100% - 158px)' }} className="d-flex flex-column justify-content-center align-items-center">
                {cartCtx.items.length === 0 && <CartEmpty />}
                {cartCtx.items.length > 0 && <> <CartList items={cartCtx.items} />
                    <Paper sx={{ px: '16px', pb: '40px', mt: 'auto', width: '100%' }} elevation={3} >
                        <CartTotals />


                        <Grid container columnSpacing={1}>
                            <Grid item xs={((infoCtx.info?.account_active === true && infoCtx.info?.temp_closed === 0) && !infoCtx.closed) ? 6 : 12}>
                                <Button fullWidth variant="outlined" color="primary" disableElevation={true} component={Link} to="/products" size="large">{t('Add More')}</Button>
                            </Grid>
                            {((infoCtx.info?.account_active === true && infoCtx.info?.temp_closed === 0) && !infoCtx.closed) && <Grid item xs={6}>
                                <Button disabled={checkoutCtx.zoneCovered === false} fullWidth variant="contained" color="primary" disableElevation={true} size="large" onClick={navigateToCheckout} >{t('Checkout')}</Button>
                            </Grid>}
                        </Grid>
                    </Paper>
                </>}
            </Box>
        </Box>
        <BottomTabs />
    </>
};

export default CartPage;