import { useContext, useEffect, useState } from "react";
import axiosConfig from "../../../../config/axios";
import {
    Box, Button, Divider, Typography, FormControl, InputLabel, Select, MenuItem, SelectChangeEvent, Grid,
    Radio, RadioGroup, FormControlLabel, FormLabel,
    Accordion, AccordionSummary, AccordionDetails
} from "@mui/material";
import locationIcon from '../../../../assets/images/LocationIcon.png';
import mapIcon from '../../../../assets/images/mapIcon.png';
import contactsIcon from '../../../../assets/images/contactsIcon2.png';
import AuthContext from "../../../../store/auth-context";
import { useNavigate, Link, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import CheckoutContext from "../../../../store/checkout-context";
import CitiesBranchesSelector from "../../../../components/CitiesBranchesSelector/CitiesBranchesSelector";
import BranchChangeDialog from "../../../../components/BranchChangeDialog/BranchChangeDialog";
import CartContext from "../../../../store/cart-context";
import { useBranchCities, useBranches } from '../../../../hooks/useBranchCities';
import SuspenseLoader from "../../../../components/SuspenseLoader/SuspenseLoader";


const BranchInfo = (props) => {

    const { t } = useTranslation();

    return (
        <Box sx={{ border: '1px solid rgba(66, 102, 104, 0.1)', p: '16px', borderRadius: '10px', mb: '16px' }}>
            <Typography sx={{ marginBottom: '16px' }} fontWeight="bold">{t('Branch Details')}</Typography>
            {props?.city && <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start', mb: '16px' }}>
                <Box sx={{ mt: '2px', mr: '16px', width: '25px' }}>
                    <img src={mapIcon} style={{ maxWidth: '100%', height: 'auto' }} />
                </Box>
                <Box>
                    <Typography color="#426668" variant="body2">{props?.city}</Typography>
                </Box>
            </Box>}

            {props.info?.address && <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start', mb: '16px' }}>
                <Box sx={{ mt: '2px', mr: '16px', width: '25px' }}>
                    <img src={locationIcon} style={{ maxWidth: '100%', height: 'auto' }} />
                </Box>
                <Box>
                    <Typography color="#426668" variant="body2">{props.info?.address}</Typography>
                </Box>
            </Box>}

            {props.info?.phone && <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start' }}>
                <Box sx={{ mt: '2px', mr: '16px', width: '25px' }}>
                    <img src={contactsIcon} style={{ maxWidth: '100%', height: 'auto' }} />
                </Box>
                <Box>
                    <Typography color="#426668" variant="body2"><a style={{ color: 'inherit', textDecoration: 'none' }} href={`tel:${props.info?.phone}`}>{props.info?.phone}</a></Typography>
                </Box>
            </Box>}
        </Box>
    )
}


const PickUpOption = () => {
    const authCtx = useContext(AuthContext);
    const cartCtx = useContext(CartContext);

    const checkoutCtx = useContext(CheckoutContext);
    const [cities, setCities] = useState([]);
    const [selectedCity, setSelectedCity] = useState(authCtx.branch?.city_name);
    const [selectedBranch, setSelectedBranch] = useState(authCtx.branch);
    const [selectedBranchId, setSelectedBranchId] = useState(authCtx.branch?.id);
    const [cityBranches, setCityBranches] = useState([]);
    const { t, i18n } = useTranslation();
    const { state } = useLocation();
    const navigate = useNavigate();
    const [accordionExpanded, setAccordionExpanded] = useState(false);
    const [branchChangeDialogOpened, setBranchChangeOpen] = useState(false);
    const citiesQuery = useBranchCities(); 
    const branchesQuery = useBranches(); 

    const handleAccordionChange = (panel) => (event, isExpanded) => {
        checkoutCtx.setCity(panel);
        setAccordionExpanded(isExpanded ? panel : false);
    };

    const fetchBranches = async () => {
        const branchesRes = await axiosConfig.get(`/${i18n.resolvedLanguage}/branches`);
        setCityBranches(branchesRes.data.branches);
    }

    const onBranchChange = (event) => {

        if (event.target.value !== '') {

            const selectedBranchData = branchesQuery.data.find((branch) => {
                return parseInt(branch.id) === parseInt(event.target.value);
            });
            setSelectedBranchId(event.target.value);
            setSelectedBranch(selectedBranchData);
            setSelectedCity(selectedBranchData.city_name);

        } else {
            alert('No branch cover this area');
            setSelectedBranch(undefined)
        };
    };

    useEffect(() => {
        if (cityBranches.length === 1) {
            setSelectedBranch(cityBranches[0]);
            setSelectedBranchId(cityBranches[0].id);

        };
    }, [cityBranches])

    useEffect(() => {
        fetchBranches();
    }, [i18n.resolvedLanguage]);

    const saveUserBranch = () => {
        authCtx.saveBranch(selectedBranch);
        checkoutCtx.setDeliveryType('pickup');
        checkoutCtx.saveDeliveryAddress({ address: null, fees: null });
        if (state?.to !== undefined) {
            navigate(state?.to);
        } else {
            navigate('/products');
        }

    }

    const onSaveUserBranchPress = () => {
        if (cartCtx.items.length > 0 && selectedBranch?.id !== authCtx.branch?.id) {
            setBranchChangeOpen(true);
        } else {
            saveUserBranch();
        }
    }

    return (
        <Box>
            <Box sx={{ width: '90%', my: '30px', mx: 'auto' }}>
                <Typography color="#172327" textAlign="center" fontWeight="bold" fontSize={18} lineHeight="1.2" sx={{ mb: '10px' }} >{t('Please select the branch to pick up')}</Typography>
            </Box>
           { (!citiesQuery.isLoading && citiesQuery.data?.length > 0) && <CitiesBranchesSelector onBranchChange={onBranchChange} cities={citiesQuery.data} selectedBranchId={selectedBranchId} />}
           {(citiesQuery.isLoading ) && <SuspenseLoader />}

            <BranchChangeDialog active={branchChangeDialogOpened} onAgree={() => { cartCtx.clear(); saveUserBranch(); }} onDismiss={() => setBranchChangeOpen(false)} />
            {selectedBranch?.id ? <BranchInfo info={selectedBranch} city={selectedCity} /> : null}
            {selectedBranch?.id ? <Button variant="contained" disabled={!selectedBranch} disableElevation size="large" fullWidth onClick={onSaveUserBranchPress}>{t('Confirm')}</Button> : null}
        </Box>
    );
};

export default PickUpOption;