import {
  Box, Toolbar, IconButton,Typography,
  useTheme,
} from "@mui/material";
import ArrowBack from "@mui/icons-material/ArrowBack";
import ArrowForward from '@mui/icons-material/ArrowForward'
import { useLocation, useNavigate } from "react-router-dom";
import GoogleMapsLocation from '../../components/google_maps/googleMaps';
import { useTranslation } from "react-i18next";

const LocationPage = () => {
  const { t, i18n } = useTranslation();
  const { state } = useLocation();
  const navigate = useNavigate();
  const theme = useTheme();

  return <Box className="LocationPage" sx={{ height: '100%' }}>
    <Toolbar sx={{ backgroundColor: theme.palette.primary.light }}>
      <IconButton
        size="large"
        edge="start"
        color="inherit"
        aria-label="menu"
        sx={{ mr: 2, position: "relative", zIndex: 100, backgroundColor: 'transparent' }}
        onClick={() => {

          navigate(-1);

        }}
      >
        {i18n.resolvedLanguage === 'en' && <ArrowBack htmlColor={theme.palette.primary.contrastText} />}
        {i18n.resolvedLanguage === 'ar' && <ArrowForward htmlColor={theme.palette.primary.contrastText} />}
      </IconButton>


      <Box
        sx={{ flex: 1, textAlign: "center", cursor: "pointer", position: 'absolute', left: 0, top: 0, width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <Typography
          variant="h6"
          component="div"
          color={theme.palette.primary.contrastText}
          fontSize="18px"
          textAlign="center"
          fontWeight="bold"
          sx={{ flexGrow: 1 }}
        >
          {t('Delivery Location')}
        </Typography>
</Box>

    </Toolbar>
    <GoogleMapsLocation city={state?.city} />
  </Box>
};

export default LocationPage;