import { createContext, useContext, useEffect, useReducer, useState } from "react";
import CustomerContext from "./customer-context";

const CheckoutContext = createContext(
    {
        city: null,
        pendingCheckout: null,
        dineInOption:null,
        setDineInOption: () => { },
        setPendingCheckout: () => { },
        setPendingProceedCheckout: () => { },
        setCity: () => { },
        deliveryType: null,
        coupon: null,
        deliverAddress: { address: null, fees: null, zid:null },
        zoneCovered: null,
        setZoneCovered: (bo) => { },
        saveDeliveryAddress: (data) => { },
        setCoupon: (coupon) => { },
        setDeliveryType: () => { },
        note: null,
        setNote: (note) => { },
    }
);

export const CheckoutProvider = (props) => {

    const [coupon, setCoupon] = useState();
    const [note, setNote] = useState();
    const [deliveryType, setDeliveryType] = useState();
    const deliver_address = localStorage.getItem('rdx_delivery_address');
    const [deliveryAddress, setDeliveryAddress] = useState(JSON.parse(deliver_address));
    const [isZoneCovered, setZoneCovered] = useState();
    const [city, setCity] = useState();
    const [pendingCheckout, setPendingCheckout] = useState();
    const [dineIn, setDineIn] = useState();

    useEffect(()=>{
     

        if(localStorage.getItem('rdx_delivery')){
            setDeliveryType(localStorage.getItem('rdx_delivery'));
        };

        if(localStorage.getItem('rdx_dinein')){
            setDineIn(JSON.parse(localStorage.getItem('rdx_dinein')));
        };
    },[]);



    const checkoutCtx = {
        city: city,
        deliveryType: deliveryType,
        deliverAddress: deliveryAddress,
        coupon: coupon,
        note: note,
        dineInOption:dineIn,
        setDineInOption: (section,table) => {
            if(section && table){
                const dineInData = {section:section,table:table};
                setDineIn(dineInData);
                localStorage.setItem('rdx_dinein', JSON.stringify(dineInData));
            }
           
         },
        setNote: (note) => { setNote(note) },
        zoneCovered: isZoneCovered,
        pendingCheckout:pendingCheckout,
        setPendingCheckout: (bool) => {
            setPendingCheckout(bool);
        },
        setZoneCovered: (bo) => {
            setZoneCovered(bo);
        },
        setCoupon: (cpn) => {
            setCoupon(cpn);
        },
        saveDeliveryAddress: (data) => {
            setDeliveryAddress(data);
            localStorage.setItem('rdx_delivery_address', JSON.stringify(data));
        },
        setDeliveryType: (type) => {
            setDeliveryType(type);
            localStorage.setItem('rdx_delivery', type);
        },
        setCity: (data) => { setCity(data) },
    }


    return (
        <CheckoutContext.Provider value={checkoutCtx}>{props.children}</CheckoutContext.Provider>
    )
}

export default CheckoutContext;