import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useBranchCities, useBranches } from '../../hooks/useBranchCities';
import { Box, Button, TextField, Typography } from '@mui/material';
import AuthContext from '../../store/auth-context';
import CheckoutContext from '../../store/checkout-context';
import { useLocation, useNavigate } from 'react-router-dom';
import CartContext from '../../store/cart-context';
import BranchChangeDialog from '../BranchChangeDialog/BranchChangeDialog';
import CustomerContext from '../../store/customer-context';
import axiosInstance from '../../config/axios';

const DeliveryDropdownSelector = ({ showAddress }) => {
    const location = useLocation();
    const branchCitiesQuery = useBranchCities();
    const customerCtx = useContext(CustomerContext);
    const navigate = useNavigate();
    const authCtx = useContext(AuthContext);
    const cartCtx = useContext(CartContext);
    const checkoutCtx = useContext(CheckoutContext);
    const [selectedCity, setSelectedCity] = useState(checkoutCtx?.deliverAddress?.city);
    const [selectedCityData, setSelectedCityData] = useState();

    const [selectedBranch, setSelectedBranch] = useState(authCtx.branch?.id);
    const [branches, setBranches] = useState([]);
    const [zones, setZones] = useState([]);
    const [selectedZone, setSelectedZone] = useState(checkoutCtx?.deliverAddress?.zid);
    const [selectedBranchData, setSelectedBranchData] = useState();
    const [selectedZoneData, setSelectedZoneData] = useState({ id: checkoutCtx?.deliverAddress?.zid, title: checkoutCtx?.deliverAddress?.zName });
    const [branchChangeDialogOpened, setBranchChangeDialogueOpen] = useState(false);
    const [address, setAddress] = useState(checkoutCtx.deliverAddress?.address || customerCtx.info?.address || '');
    const { t } = useTranslation();
    const branchesQuery = useBranches();



    useEffect(() => {
        if (!branchCitiesQuery.isLoading && branchCitiesQuery.data?.length > 0 && !branchesQuery.isLoading && branchesQuery.data?.length > 0) {
            const city = branchCitiesQuery.data?.find(city => city.id === checkoutCtx?.deliverAddress?.city);
            setSelectedCityData(city);
            // console.log(branchesQuery.data);

            if (city) {
                setBranches(city?.branches);
                const branchData = branchesQuery.data?.find(branch => parseInt(branch.id) === parseInt(authCtx.branch?.id));
                if (branchData) {
                    setSelectedBranchData(branchData);
                    setSelectedBranch(branchData?.id);
                    setZones(branchData?.deliveryzones);
                    const zoneSel = branchData?.deliveryzones?.find(zone => zone.id === checkoutCtx?.deliverAddress?.zid);
                    // console.log(checkoutCtx?.deliverAddress?.zid, branchData?.deliveryzones);
                    if (zoneSel) {
                        setSelectedZone(zoneSel.id);
                        setSelectedZoneData(zoneSel);
                    };
                } else {
                    console.log('could not get the current address info')
                }
            }

        }


    }, [branchCitiesQuery.data,
    branchCitiesQuery.isLoading,
    branchesQuery.isLoading,
    branchesQuery.data
    ]);

    const handleCityChange = (e) => {
        setBranches([]);
        setZones([]);
        setSelectedBranchData(undefined);
        setSelectedBranch(undefined);
        setSelectedZone(undefined);
        setSelectedZoneData(undefined);

        const value = e.target.value;
        const city = branchCitiesQuery.data.find(city => city.id === value);
        setBranches(city['branches']);
        setSelectedCityData(city);
        setSelectedCity(value);
    };


    const handleBranchChange = (e) => {
        setSelectedZoneData(undefined);
        setSelectedZone(undefined);
        setZones([]);
        const value = e.target.value;
        const branchData = branchesQuery.data.find(branch => parseInt(branch.id) === parseInt(value));
        setSelectedBranchData(branchData);
        setSelectedBranch(value);
        setZones(branchData['deliveryzones']);
    };

    const handleBranchZonesChange = (e) => {
        const value = e.target.value;
        const zoneData = zones.find(zone => parseInt(zone.id) === parseInt(value));
        setSelectedZoneData(zoneData);
        setSelectedZone(value);

    };

    const saveZoneHandler = async () => {
        authCtx.saveBranch(selectedBranchData);
        checkoutCtx.setDeliveryType('delivery');
        if (selectedZoneData.id) {
            checkoutCtx.saveDeliveryAddress({ city: selectedCity, cityName: selectedCityData?.name, address: address || null, fees: selectedZoneData?.fees, id: selectedBranchData?.id, zid: selectedZoneData?.id, zName: selectedZoneData?.title });
            if (authCtx.token && address) {
                await axiosInstance.post('/customer/info/update', { ...customerCtx.info, 'address': address });
                customerCtx.fetch();
            };

            if (location.pathname === '/delivery-information') {
                if (cartCtx.items.length > 0 && (address || checkoutCtx.deliverAddress?.address)) {
                    navigate('/checkout-confirm');
                } else {
                    navigate('/products');
                }

            } else {
                navigate(-1);
            };
        }

    }


    const onSaveUserBranchPress = () => {
        // console.log('compare zone selected vs saved', selectedZoneData.id, checkoutCtx.deliverAddress.zid);
        // console.log('compare branch selected vs saved', selectedBranch, authCtx.branch.id);
        if ((cartCtx.items.length > 0 && (selectedBranch !== authCtx.branch.id))) {
            setBranchChangeDialogueOpen(true);
        } else {
            saveZoneHandler();
        }
    }


    const addressChangeHandler = (e) => {
        setAddress(e.target.value);
    }


    return <div>

        <FormControl variant="filled" sx={{ mb: 1, width: '100%' }}>
            <InputLabel id="demo-simple-select-filled-label">{t('Select your city')}</InputLabel>
            <Select
       MenuProps={{
        style: {
           maxHeight: "50vh",
              },
        }}
                labelId="demo-simple-select-filled-label"
                id="demo-simple-select-filled"
                defaultValue={checkoutCtx?.deliverAddress?.city}
                onChange={handleCityChange}
            >
                <MenuItem value="" disabled></MenuItem>
                {branchCitiesQuery.data?.map(city => {
                    return <MenuItem key={city.id} value={city.id}>{city.name}</MenuItem>
                })}

            </Select>
        </FormControl>
        <FormControl disabled={branches?.length === 0 || !selectedCity} variant="filled" sx={{ mb: 1, width: '100%' }}>
            <InputLabel id="demo-simple-select-filled-label">{t('Select nearest branch')}</InputLabel>
            <Select
            MenuProps={{
                style: {
                   maxHeight: "50vh",
                      },
                }}
                labelId="demo-simple-select-filled-label"
                id="demo-simple-select-filled"
                defaultValue={ checkoutCtx.deliveryType === 'delivery' ?  authCtx.branch?.id : undefined}
                onChange={handleBranchChange}
            >
                <MenuItem value="" disabled></MenuItem>
                {branches?.length > 0 && branches?.map(branch => {
                    return <MenuItem key={branch?.id} value={branch?.id}>{branch?.name}</MenuItem>
                })}

            </Select>
        </FormControl>
        <FormControl disabled={zones?.length === 0 || !selectedCity || !selectedBranch} variant="filled" sx={{ mb: 1, width: '100%' }}>
            <InputLabel id="demo-simple-select-filled-label">{t('Select your area')}</InputLabel>
            <Select
          MenuProps={{
            style: {
               maxHeight: "50vh",
                  },
            }}
                labelId="demo-simple-select-filled-label"
                id="demo-simple-select-filled"
                defaultValue={checkoutCtx.deliverAddress?.zid}
                onChange={handleBranchZonesChange}
            >
                <MenuItem value="" disabled></MenuItem>
                {zones?.map(zone => {
                    return <MenuItem key={zone.id} value={zone.id}>{zone.title}</MenuItem>
                })}

            </Select>
        </FormControl>
        {showAddress && <Box sx={{ mb: 2 }}>
            <TextField rows={4} multiline onChange={addressChangeHandler} value={address} defaultValue={address} id="filled-basic" sx={{ width: '100%',mb:1 }} label={t('Delivery address')} variant="filled" />
                <Typography fontSize={12}>Please provide us with your street name, building number, floor and any extra details for delivery</Typography>
        </Box>}
        {showAddress && <Button disableElevation
            disabled={!selectedZone || !selectedZoneData || !address || (address && address.length < 5)}
            sx={{ width: '100%' }}
            size="large"
            variant="contained"
            onClick={onSaveUserBranchPress}>{t('Continue')}</Button>
        }
        {!showAddress && <Button disableElevation
            disabled={!selectedZone || !selectedZoneData}
            sx={{ width: '100%' }}
            size="large"
            variant="contained"
            onClick={onSaveUserBranchPress}>{t('Start Ordering')}</Button>
        }
        <BranchChangeDialog active={branchChangeDialogOpened} onAgree={() => { cartCtx.clear(); saveZoneHandler(); }} onDismiss={() => setBranchChangeDialogueOpen(false)} />

    </div>
};

export default DeliveryDropdownSelector;