import { Typography, Toolbar, AppBar, IconButton, Box, useTheme, Alert, Button, Stack } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import ArrowBack from "@mui/icons-material/ArrowBack";
import ArrowForward from '@mui/icons-material/ArrowForward'
import Styles from "./CustomAppBar.module.css";
import NotificationsOutlined from "@mui/icons-material/NotificationsOutlined";
import { Link, useNavigate } from "react-router-dom";
import CartIcon from "../Icons/CartIcon";
import { useContext } from "react";
import CartContext from "../../store/cart-context";
import AuthContext from "../../store/auth-context";
import { useTranslation } from "react-i18next";
import CheckoutContext from "../../store/checkout-context";
import InfoContext from "../../store/info-context";
import ClosedBanner from "../ClosedBanner/ClosedBanner";

const CustomAppBar = ({
    isFloating,
    hasMenu,
    hasBack,
    backRoute,
    backDark,
    gpsTop,
    title,
    enableGps,
    disableGpsLink,
    notif,
    cart
}) => {
    const navigate = useNavigate();
    const cartCtx = useContext(CartContext);
    const authCtx = useContext(AuthContext);
    const checkoutCtx = useContext(CheckoutContext);
    const infoCtx = useContext(InfoContext);
    const { t, i18n } = useTranslation();
    const theme = useTheme();

    return (
        <Box>
            {infoCtx.info?.account_active === true && <AppBar
                position={"fixed"}
                elevation={0}
                sx={isFloating&& { backgroundColor: 'transparent' }}
                color={"primary"}
                className={`${Styles.AppBar} ${i18n.resolvedLanguage === 'ar' && Styles.AppBar_rtl}`}
            >
                <ClosedBanner />
                <Toolbar>
                    {hasMenu && (
                        <IconButton
                            size="large"
                            edge="start"
                            color="inherit"
                            aria-label="menu"
                            sx={{ mr: 2 }}
                        >
                            <MenuIcon />
                        </IconButton>
                    )}
                    {(hasBack && !backRoute) && (
                        <IconButton
                            size="large"
                            edge="start"
                            color="inherit"
                            aria-label="menu"
                            sx={{ mr: 2, position: "relative", zIndex: 100, backgroundColor: !backDark ? '#fff8' : 'transparent' }}
                            onClick={() => { navigate(-1); }}
                        >
                            {i18n.resolvedLanguage === 'en' && <ArrowBack htmlColor={isFloating?"#000":"#fff"} />}
                            {i18n.resolvedLanguage === 'ar' && <ArrowForward htmlColor={isFloating?"#000":"#fff"} />}
                        </IconButton>
                    )}


                    {(hasBack && backRoute) && (
                        console.log(backRoute),
                        <IconButton
                            size="large"
                            edge="start"
                            color="inherit"
                            aria-label="menu"
                            sx={{ mr: 2, position: "relative", zIndex: 100, backgroundColor: !backDark ? '#fff8' : 'transparent' }}
                            onClick={() => {
                                navigate(backRoute);
                            }}
                        >
                            {i18n.resolvedLanguage === 'en' && <ArrowBack htmlColor={isFloating?"#000":"#fff"} />}
                            {i18n.resolvedLanguage === 'ar' && <ArrowForward htmlColor={isFloating?"#000":"#fff"} />}
                        </IconButton>
                    )}

                    {!gpsTop && <Box
                        sx={{ flex: 1, textAlign: "center", cursor: "pointer", position: 'absolute', left: 0, top: 0, width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        {title && <Typography
                            variant="h6"
                            component="div"
                            color={ isFloating ? "#426668" : "#fff"}
                            fontSize="18px"
                            textAlign="center"
                            fontWeight="bold"
                            sx={{ flexGrow: 1 }}
                        >
                            {title}
                        </Typography>
                        }


                    </Box>}
                    {(gpsTop && enableGps) && <Box
                        sx={{ overflow: 'hidden', textAlign: "center", cursor: "pointer",top: 0, flex:1,columnGap:1, height: '100%', display: 'flex', flexDirection: 'row',  alignItems: 'center',justifyContent:'center' }} onClick={() => {
                            if (!disableGpsLink) {
                                navigate('/delivery');
                            }
                        }}>
                        {checkoutCtx.deliveryType === 'delivery' && <Typography
                            variant="h6"
                            component="span"
                            color={'#fff'}
                            fontSize="14px"
                            fontWeight="bold"
                            noWrap
                        >
                            {t('Delivery to')}
                        </Typography>}
                        {checkoutCtx.deliveryType === 'pickup' && <Typography
                            variant="h6"
                            component="span"
                            color={'#fff'}
                            fontSize="14px"
                            fontWeight="bold"
                            title={checkoutCtx.deliverAddress?.zName}
                        >
                            {t('Pick Up')}
                        </Typography>}
                        {checkoutCtx.deliveryType === 'delivery' && <Typography
                            variant="h6"
                            component="span"
                            color={'#fff'}
                            fontSize="14px"
                            fontWeight="normal"
                            noWrap
                        >
                            {checkoutCtx.deliverAddress?.address}
                            { checkoutCtx.deliverAddress?.address && checkoutCtx.deliverAddress?.zName && <span style={{display:'inline-block',margin:'0px 1px'}}>,&nbsp;</span> }
                            {checkoutCtx.deliverAddress?.zName}

                        </Typography>}
                        {checkoutCtx.deliveryType === 'pickup' && <Typography
                            variant="h6"
                            component="span"
                            color="#fff"
                            fontSize="14px"
                            fontWeight="normal"

                        >
                            {authCtx.branch?.name}
                        </Typography>}
                        {checkoutCtx.deliveryType === 'dinein' && <Typography
                            variant="h6"
                            component="span"
                            color="#426668"
                            fontSize="14px"
                            fontWeight="normal"

                        >
                            {authCtx.branch?.name}, Section  {checkoutCtx.dineInOption.section}, Table  {checkoutCtx.dineInOption.table}
                        </Typography>}
                        {!checkoutCtx.deliveryType && <Typography
                            variant="h6"
                            component="span"
                            color="#fff"
                            fontSize="14px"
                            fontWeight="normal"

                        >
                            {infoCtx.info?.delivery_pickup === 'all' && <>
                                {t('Select your Location for delivery')} <br />
                                {t('or Branch for Pickup')}
                            </>}
                            {infoCtx.info?.delivery_pickup === 'delivery' && <>
                                {t('Select your Location for delivery')}
                            </>}
                            {infoCtx.info?.delivery_pickup === 'pickup' && <>
                                {t('Select your Branch for Pickup')}
                            </>}
                        </Typography>}
                    </Box>}

                    {notif ? <IconButton
                        size="small"
                        edge="start"
                        color="inherit"
                        aria-label="menu"
                        sx={{ ml: "auto" }}
                        component={Link}
                        to="/notifications"
                    >
                        <NotificationsOutlined htmlColor={isFloating?"#000":"#fff"} />
                    </IconButton> : <div style={{width:'50px'}}/>}

                    {cart && <IconButton
                        size="small"
                        edge="start"
                        color="inherit"
                        aria-label="cart"
                        sx={{ ml: "auto", backgroundColor: !backDark ? '#fff8' : 'transparent', p: '15px' }}
                        onClick={() => {
                            navigate('/cart');
                        }}
                    >
                        {cartCtx.items.length > 0 && <span className={Styles.cartBadge}></span>}
                        <CartIcon color={isFloating?"#000":"#fff"} />
                    </IconButton>}
                </Toolbar>
                {(!gpsTop && enableGps && checkoutCtx.deliveryType) && <Toolbar sx={{backgroundColor:'#fff'}}>

                    <Box sx={{ textAlign: 'center', width: '100%',cursor:'pointer',columnGap:'0',display:'flex',justifyContent:'center',alignItems:'center' }} onClick={() => {
                        if (!disableGpsLink) {
                            navigate('/delivery');
                        };
                    }}>
                        {checkoutCtx.deliveryType === 'delivery' && <> <Typography
                            variant="h6"
                            component="span"
                            color={theme.palette.primary.main}
                            fontSize="14px"
                            fontWeight="normal"
                            sx={{ mx:1 }}
                        >
                            {t('Delivery to')}
                        </Typography>
                            <Typography
                                variant="h6"
                                component="span"
                                color={"#000"}
                                fontSize="14px"
                                fontWeight="normal"
                                noWrap
                                title={checkoutCtx.deliverAddress?.zName}
                            >
                                {checkoutCtx.deliverAddress?.address} 
                               { checkoutCtx.deliverAddress?.address && checkoutCtx.deliverAddress?.zName && <span style={{display:'inline-block',margin:'0px 1px'}}>,&nbsp;</span> }
                                {checkoutCtx.deliverAddress?.zName}
                            </Typography>
                        </>}
                        {checkoutCtx.deliveryType === 'pickup' && <Box> <Typography
                            variant="h6"
                            component="span"
                            color={theme.palette.primary.main}
                            fontSize="14px"
                            fontWeight="normal"
                        >{t('Pick Up')}</Typography>
                            <Typography
                                variant="h6"
                                component="span"
                                color="#426668"
                                fontSize="14px"
                                fontWeight="normal"
                                
                            > : {authCtx.branch?.name}
                            </Typography>
                        </Box>}
                        {checkoutCtx.deliveryType === 'dinein' && <> <Typography
                            variant="h6"
                            component="span"
                            color={theme.palette.primary.main}
                            fontSize="14px"
                            fontWeight="normal"
                        >
                            {t('Dine in')}
                        </Typography>
                            <Typography
                                variant="h6"
                                component="span"
                                color="#426668"
                                fontSize="14px"
                                fontWeight="normal"
                                noWrap
                            >
                                {authCtx.branch?.name},  Section {checkoutCtx.dineInOption.section}, Table {checkoutCtx.dineInOption.table}
                            </Typography>
                        </>}
                        {!checkoutCtx.deliveryType && <Typography
                            variant="h6"
                            component="span"
                            color="#426668"
                            fontSize="14px"
                            fontWeight="normal"

                        >
                            {t('Select your Location for delivery')} <br />
                            {t('or Branch for Pickup')}
                        </Typography>}
                    </Box>
                </Toolbar>}
                {(!gpsTop && enableGps && !checkoutCtx.deliveryType) && <Alert sx={{ cursor: 'pointer' }} action={
                    <Button color="inherit" size="small">
                        {t('Change')}
                    </Button>
                } severity="warning" onClick={() => {
                    if (!disableGpsLink) {
                        navigate('/delivery');
                    };
                }}>
                    {t('Select your Location for delivery')} {t('or Branch for Pickup')}
                </Alert>}
            </AppBar>}

            {infoCtx.info?.account_active === false && <AppBar
                position={"fixed"}
                elevation={0}
                sx={{ backgroundColor: isFloating ? 'transparent' : '#fff' }}
                color={"transparent"}
                className={`${Styles.AppBar} ${i18n.resolvedLanguage === 'ar' && Styles.AppBar_rtl}`}
            >
                <Toolbar>
                    {hasMenu && (
                        <IconButton
                            size="large"
                            edge="start"
                            color="inherit"
                            aria-label="menu"
                            sx={{ mr: 2 }}
                        >
                            <MenuIcon />
                        </IconButton>
                    )}
                    {(hasBack && !backRoute) && (
                        <IconButton
                            size="large"
                            edge="start"
                            color="inherit"
                            aria-label="menu"
                            sx={{ mr: 2, position: "relative", zIndex: 100, backgroundColor: !backDark ? '#fff8' : 'transparent' }}
                            onClick={() => {

                                navigate(-1);

                            }}
                        >
                            {i18n.resolvedLanguage === 'en' && <ArrowBack htmlColor={isFloating?"#000":"#fff"} />}
                            {i18n.resolvedLanguage === 'ar' && <ArrowForward htmlColor={isFloating?"#000":"#fff"} />}
                        </IconButton>
                    )}


                    {(hasBack && backRoute) && (
                        <IconButton
                            size="large"
                            edge="start"
                            color="inherit"
                            aria-label="menu"
                            sx={{ mr: 2, position: "relative", zIndex: 100, backgroundColor: !backDark ? '#fff8' : 'transparent' }}
                            onClick={() => {
                                navigate(backRoute);
                            }}
                        >
                            {i18n.resolvedLanguage === 'en' && <ArrowBack htmlColor={isFloating?"#000":"#fff"} />}
                            {i18n.resolvedLanguage === 'ar' && <ArrowForward htmlColor={isFloating?"#000":"#fff"} />}
                        </IconButton>
                    )}

                    {!gpsTop && <Box
                        sx={{ flex: 1, textAlign: "center", cursor: "pointer", position: 'absolute', left: 0, top: 0, width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        {title && <Typography
                            variant="h6"
                            component="span"
                            color="#426668"
                            fontSize="18px"
                            textAlign="center"
                            fontWeight="bold"
                            sx={{ flexGrow: 1 }}
                        >
                            {title}
                        </Typography>
                        }



                    </Box>}

                    {(gpsTop && title && infoCtx.info?.account_active === false) && <Box
                        sx={{ flex: 1, textAlign: "center", cursor: "pointer", position: 'absolute', left: 0, top: 0, width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        {title && <Typography
                            variant="h6"
                            component="span"
                            color="#426668"
                            fontSize="18px"
                            textAlign="center"
                            fontWeight="bold"
                            sx={{ flexGrow: 1 }}
                        >
                            {title}
                        </Typography>
                        }



                    </Box>}


                    {cart && <IconButton
                        size="small"
                        edge="start"
                        color="inherit"
                        aria-label="cart"
                        sx={{ ml: "auto", backgroundColor: !backDark ? '#fff8' : 'transparent', p: '15px' }}
                        onClick={() => {
                            navigate('/cart');
                        }}
                    >
                        {cartCtx.items.length > 0 && <span className={Styles.cartBadge}></span>}
                        <CartIcon color={isFloating?"#000":"#fff"} />
                    </IconButton>}
                </Toolbar>



            </AppBar>}
            {infoCtx.closed && <Box sx={{ height: '60px' }}></Box>}
            {!isFloating && <Box sx={{ height: (enableGps && !gpsTop) ? '120px' : '60px' }}></Box>}
        </Box>
    );
};

export default CustomAppBar;
