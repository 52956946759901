import React, { useContext, useState, useEffect } from "react";
import { useNavigate, Link } from 'react-router-dom';
import { Button, Typography, Divider, Box, Grid, FormControl, RadioGroup, Alert, OutlinedInput, FormControlLabel, Radio, TextField, InputAdornment, ButtonBase, IconButton, useTheme } from "@mui/material";
import CustomAppBar from "../../components/CustomAppBar/CustomAppBar";
import { useTranslation } from "react-i18next";
import CartContext from "../../store/cart-context";
import AuthContext from "../../store/auth-context";
import CustomerContext from "../../store/customer-context";
import InfoContext from "../../store/info-context";
import axiosConfig from "../../config/axios";
import CheckoutContext from "../../store/checkout-context";
import { Edit } from "@mui/icons-material";


const CheckoutConfirmPage = () => {
    const theme = useTheme();
    const cartCtx = useContext(CartContext);
    const authCtx = useContext(AuthContext);
    const customerCtx = useContext(CustomerContext);
    const checkoutCtx = useContext(CheckoutContext);
    const infoCtx = useContext(InfoContext);
    const [setPaymentMethod] = useState('cash');
    const navigate = useNavigate();
    const { t, i18n } = useTranslation();
    const [couponCode, setCouponCode] = useState();
    const [note, setNote] = useState(checkoutCtx.note || '');
    const [couponErrorMessage, setCouponErrorMessage] = useState();
    const [placeOrderLocked, setPlaceOrderLocked] = useState(false);
    const [deliveryFees, setDeliveryFees] = useState(checkoutCtx.deliverAddress?.fees);


    const paymentMethodChange = (e) => {
        const value = e.target.value;
        setPaymentMethod(value);
    }

    const applyCouponHandler = async () => {
        setCouponErrorMessage(undefined);
        const data = {
            coupon_code: couponCode,
            total_amount: cartCtx.total,
            lang: i18n.resolvedLanguage
        };
        try {
            const couponCheckRes = await axiosConfig.post('/coupon/check', data, { headers: { 'Authorization': `Bearer ${authCtx.token}` } });
            checkoutCtx.setCoupon(couponCheckRes.data.coupon);
        } catch (error) {
            if (error.response.data?.message) {
                setCouponErrorMessage(error.response.data?.message);
            } else {
                setCouponErrorMessage(error.response.data?.errors?.coupon_code);
            }
        }
    };

    const placeOrderHandler = async () => {
        setPlaceOrderLocked(true);

        let orderItems = cartCtx.items.map(item => {
            return {
                id: item.id,
                qty: item.qty,
                note: item.note,
                extras: [...item.extras.map(extra => { return { id: extra.id, qty: extra.qty || 1 } }), ...item.generalExtras
                    .map(extra => { return { id: extra.id, qty: extra.qty || 1 } })],
            }
        });




        let order = {
            note: note || '',
            payment: 'cash',
            type: checkoutCtx.deliveryType,
            branch_id: authCtx.branch?.id,
            section_id: checkoutCtx.dineInOption?.section || null,
            table_id: checkoutCtx.dineInOption?.table || null,
            items: orderItems,
            delivery_fees: checkoutCtx.deliverAddress?.fees || null,
            coupon_code: checkoutCtx.coupon_code || couponCode || null,
            address: checkoutCtx.deliveryType === 'delivery' ? `${checkoutCtx.deliverAddress?.address}, ${checkoutCtx.deliverAddress?.zName}, ${checkoutCtx.deliverAddress?.cityName}` : null
        };

        try {

            const orderRes = await axiosConfig.post(`/${i18n.resolvedLanguage}/order/store`, order);
            infoCtx.refetch();
            cartCtx.clear();
            checkoutCtx.setCoupon(undefined);
            checkoutCtx.setNote(undefined);
            // console.log('orderRes', orderRes);
            navigate('/checkout-order-placed', { state: { order: orderRes.data.order } });
        } catch (error) {
            console.log('order res error', error.response);
            setPlaceOrderLocked(false);
        };
    }

    const saveNote = () => {
        checkoutCtx.setNote(note);
    }


    const couponCalculation = () => {
        if (checkoutCtx.coupon) {
            if (checkoutCtx.coupon.type === 'percent') {
                return ((cartCtx.total * checkoutCtx.coupon.amount) / 100);
            } else {
                return checkoutCtx.coupon.amount;
            }
        } else {
            return null;
        }
    }

    const taxCalculation = () => {
        if (infoCtx?.tax && infoCtx.tax?.number) {
            if (infoCtx.info?.tax_included === 0) {
                return ((infoCtx.tax?.number * ((cartCtx.total - couponCalculation()) + (checkoutCtx.deliverAddress?.fees || 0))) / 100) || ((cartCtx.total - couponCalculation()) + (checkoutCtx.deliverAddress?.fees || 0));
            };
            if (infoCtx.info?.tax_included === 1) {
                return 0;
            };
        } else {
            return null;
        }
    }

    const totalCalculation = () => {
        return ((cartCtx.total - couponCalculation()) + (checkoutCtx.deliverAddress?.fees || 0) + (taxCalculation()) || 0)
    }

    const getDeliveryFees = async (zid) => {
        try {
            const deliveryZoneRes = await axiosConfig.get(`/${i18n.resolvedLanguage}/delivery-zones/${zid}`);
            setDeliveryFees(deliveryZoneRes.data[0]?.fees);
        } catch (error) {
            console.log('dsw', error);
        }
    }

    useEffect(() => {
        // console.log('checkoutCtx.deliverAddress', checkoutCtx.deliverAddress);
        getDeliveryFees(checkoutCtx.deliverAddress?.zid);
    }, [checkoutCtx.deliverAddress?.zid]);

    return (
        <Box id="CheckoutRegisterPage">
            <CustomAppBar hasBack backRoute={'/cart'} backDark notif title={t("Checkout")} />
            <Box sx={{ p: '16px' }}>
            <Typography variant="h6">{t('Confirm Order')}</Typography>
            <Typography sx={{mb:'30px'}}>{t('Please take a moment to confirm your order details, then click "Place Order" to complete.')}</Typography>
                {checkoutCtx.deliveryType === 'delivery' &&
                    <>
                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mb: '16px' }}>
                            <Box>
                                <Typography variant="body1" fontWeight="bold">{t('Delivery address')}</Typography>
                                <Typography variant="body2">{t('Address')}: {checkoutCtx.deliverAddress?.address} 
                                    <br/> {t('Area')}: {checkoutCtx.deliverAddress.zName}
                                    <br/> {t('City')}: {checkoutCtx.deliverAddress.cityName}
                                    </Typography>
                            </Box>
                            <IconButton onClick={()=>navigate('/delivery-information',{
                                state:{
                                    from:'/checkout-confirm'
                                }
                            })}><Edit sx={{fontSize:'18px'}}/></IconButton>
                        </Box>

                        <Divider sx={{ my: '16px' }} />
                    </>}

                {checkoutCtx.deliveryType === 'pickup' &&
                    <>
                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mb: '16px' }}>
                            <Box>
                                <Typography variant="body1" fontWeight="bold">{t('Pick Up')}</Typography>
                                <Typography variant="body2">{authCtx.branch?.name}</Typography>
                            </Box>
                            {/* <Box>
                        <Button variant="text" component={Link} to="/delivery">{t('Change')}</Button>
                    </Box> */}
                        </Box>
                        <Divider sx={{ my: '16px' }} />
                    </>}
                {customerCtx.info?.name && <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mb: '16px' }}>
                    <Box>
                        <Typography variant="body1" fontWeight="bold">{t('Full Name')}</Typography>
                        <Typography variant="body2">{customerCtx.info?.name}</Typography>
                    </Box>

                </Box>}
                {customerCtx.info?.phone && <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <Box>
                        <Typography variant="body1" fontWeight="bold">{t('Mobile')}</Typography>
                        <Typography variant="body2">{customerCtx.info?.phone}</Typography>
                    </Box>

                </Box>}
                <Divider sx={{ my: '16px' }} />
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mb: '16px' }}>
                    <Box>
                        <Typography variant="body1" fontWeight="bold">{t('Item Details')}</Typography>
                    </Box>
                    <Box>
                        <Button variant="text" component={Link} to="/cart">{t('See All')}</Button>
                    </Box>
                </Box>
                <Divider sx={{ my: '16px' }} />
                <Box>
                    <Typography variant="h6" sx={{ mb: '8px' }}>{t('Payment method')}</Typography>
                    <Grid container>
                        <Grid item>
                            <FormControl>
                                <RadioGroup
                                    aria-labelledby="demo-radio-buttons-group-label"
                                    defaultValue="credit"
                                    name="payment_method"
                                    onChange={paymentMethodChange}

                                >
                                    {/* <FormControlLabel value="credit" control={<Radio />} label={t("Credit Card")} /> */}
                                    <FormControlLabel checked value="cash" control={<Radio />} label={t("Cash")} />

                                </RadioGroup>
                            </FormControl>
                        </Grid>
                    </Grid>
                </Box>
                <Divider sx={{ my: '16px' }} />
                <Box sx={{ mb: '16px' }}>
                    <Typography variant="h6" sx={{ mb: '8px' }}>{t('Coupon')}</Typography>
                    <FormControl fullWidth variant="outlined" color="primary">
                        <OutlinedInput
                            color="primary"
                            id="outlined-adornment-mobile"
                            defaultValue={checkoutCtx.coupon?.code}
                            value={couponCode}
                            onChange={(e) => { setCouponCode(e.target.value.toUpperCase()) }}
                            endAdornment={<InputAdornment position="end">
                                {!checkoutCtx.coupon && <ButtonBase onClick={applyCouponHandler}><Typography variant="body2" color="#000">{t('Apply')}</Typography></ButtonBase>}
                                {checkoutCtx.coupon && <ButtonBase onClick={() => {
                                    checkoutCtx.setCoupon(undefined);
                                    setCouponCode('');
                                }}><Typography variant="body2" color="#000">{t('Remove')}</Typography></ButtonBase>}
                            </InputAdornment>}
                        />
                    </FormControl>
                    {couponErrorMessage && <Typography color="#f00" variant="body2" sx={{ py: '5px' }}>{t(couponErrorMessage)}</Typography>}
                </Box>
                <Divider sx={{ mb: '16px' }} />
                <Box sx={{ mb: '16px' }}>
                    <Typography variant="h6" sx={{ mb: '8px' }}>{t('Notes')}</Typography>

                    <FormControl fullWidth variant="outlined" color="primary">
                        <OutlinedInput
                            color="primary"
                            id="outlined-adornment-mobile"
                            type="text"
                            value={note}
                            onChange={(e) => setNote(e.target.value)}
                            onKeyUp={saveNote}

                        />
                    </FormControl>
                </Box>
                <Divider sx={{ mb: '16px' }} />
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mb: '16px' }}>
                    <Box>
                        <Typography variant="h6" >{t('Payment Summary')}</Typography>
                    </Box>
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mb: '16px' }}>
                    <Box>
                        <Typography variant="body2" fontWeight="bold">{t('Subtotal')} ( {cartCtx.items.length} {t('item')} )</Typography>
                    </Box>
                    <Box>
                        <Typography variant="body2" > {cartCtx.total} {t('EGP')}</Typography>
                    </Box>
                </Box>


                {checkoutCtx.coupon && <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mb: '16px' }}>
                    <Box>
                        <Typography variant="body2" fontWeight="bold">{t('Coupon')} [{checkoutCtx.coupon?.code}]</Typography>
                    </Box>
                    <Box>
                        <Typography color={theme.palette.primary.main} variant="body2" > -{couponCalculation()} {t('EGP')}</Typography>
                    </Box>
                </Box>}
                {(checkoutCtx.deliveryType === 'delivery' && deliveryFees >= 0) && <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mb: '16px' }}>
                    <Box>
                        <Typography variant="body2" fontWeight="bold">{t('Delivery')}</Typography>
                    </Box>
                    <Box>
                        <Typography variant="body2" >{deliveryFees} {t('EGP')}</Typography>
                    </Box>
                </Box>}


                {(infoCtx.info?.tax_included === 0 || infoCtx.info?.tax_included === 1) && <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mb: '16px' }}>
                    <Box>
                        <Typography variant="body2" fontWeight="bold">{t('Taxes')}</Typography>
                    </Box>
                    <Box>

                        {taxCalculation() !== null && infoCtx.info?.tax_included === 0 && <Typography variant="body2" >{taxCalculation()} {t('EGP')} </Typography>}
                        {(infoCtx.info?.tax_included === 1 || taxCalculation() === null) && <Typography variant="body2" >{t('Included')} </Typography>}
                    </Box>
                </Box>}


                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mb: '16px' }}>
                    <Box>
                        <Typography variant="body2" fontWeight="bold">{t('Total')}</Typography>
                    </Box>
                    <Box>

                        <Typography variant="body2" >{totalCalculation()} {t('EGP')}</Typography>

                    </Box>
                </Box>

                {infoCtx.info?.temp_closed === 1 && <Alert severity="error" sx={{ mb: '10px' }} action={
                    <Button component={Link} to="/contacts" color="inherit" size="small">
                        {t('Working Hours')}
                    </Button>
                }>{t('Temporary Closed!')}</Alert>}



                <Box sx={{ mt: '20px' }}>
                    <Button disabled={placeOrderLocked || infoCtx.info?.temp_closed === 1 || (!customerCtx.info?.name || customerCtx.info?.name?.length < 1)} disableElevation sx={{ width: '100%', mb: '16px' }} size="large" variant="contained" onClick={placeOrderHandler}>{t('Place Order')}</Button>
                    <Button disableElevation sx={{ width: '100%' }} size="large" variant="outlined" onClick={() => navigate('/products')}>{t('Cancel')}</Button>
                </Box>
            </Box>
        </Box>
    )
};

export default CheckoutConfirmPage;

