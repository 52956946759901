import { Typography, Box } from "@mui/material";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import CheckoutContext from "../../../../store/checkout-context";
import DeliveryDropdownSelector from "../../../../components/DeliveryDropdownSelector/DeliveryDropdownSelector";

const DeliveryOption = () => {
    const checkoutCtx = useContext(CheckoutContext);
    const { t } = useTranslation();

    return (<Box>
        {checkoutCtx.deliverAddress?.address && <Box sx={{ width: '80%', my: '30px', mx: 'auto' }}>
            <Typography color="#172327" textAlign="center" >{t('Current Address')}</Typography>
            <Typography color="#aaa" fontSize="12px" textAlign="center" sx={{ mx: 'auto' }}>{checkoutCtx.deliverAddress?.address}</Typography>
           {checkoutCtx.deliverAddress?.zName && <Typography color="#aaa" fontSize="12px" textAlign="center" sx={{ mx: 'auto' }}>Area: {checkoutCtx.deliverAddress?.zName}</Typography>}
           {checkoutCtx.deliverAddress?.cityName && <Typography color="#aaa" fontSize="12px" textAlign="center" sx={{ mx: 'auto' }}>City: {checkoutCtx.deliverAddress?.cityName}</Typography>}

        </Box>}

        {checkoutCtx.deliverAddress?.address && <>
            <Box sx={{ height: '0' }} />
            <div className="or_separator">
                <Typography component="span" >{t('Or')}</Typography>
            </div>
        </>}
        <Typography sx={{ fontWeight: 'bold', fontSize: 18, lineHeight: 1.2, textAlign: 'center', marginBottom: '15px', color: '#555' }}>{!checkoutCtx.deliverAddress?.address ? t('Choose your location'): t('Change your location')}</Typography>
       
      {/* { !branchCitiesQuery.isLoading && branchCitiesQuery.data?.length > 0  && <CitiesSelector cities={branchCitiesQuery.data} /> } */}
       <DeliveryDropdownSelector showAddress={false} />     
    
    </Box>
    );
};

export default DeliveryOption;